var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-empty", style: { minHeight: _vm.height + "px" } },
    [
      _c("SvgIcon", {
        staticClass: "table-empty__icon",
        attrs: { name: _vm.svgName || "eqx-empty", size: _vm.svgSize },
      }),
      _c(
        "div",
        { staticClass: "table-empty__text" },
        [
          _vm._t("text", function () {
            return [_vm._v(" " + _vm._s(_vm.text) + " ")]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }