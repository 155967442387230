// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// new Date().Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// new Date().Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
function format(fmt) {
    // author: meizz
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
        }
    }
    return fmt;
}

function formatDate(date, format) {
    return new Date(date).format(format);
}

function daysBefore(step, type = 'yyyy-MM-dd') {
    let theDate = new Date();
    theDate.setDate(theDate.getDate() - step);
    if (type === '') {
        return Number(theDate);
    }
    return formatDate(theDate, type);
}

/**
 * 格式化填写时长
 * @param {*} seconds 秒
 */
function formatSeconds(seconds) {
    if (!seconds) {
        return 0;
    }
    let s = parseInt(seconds);
    let m = 0;
    let h = 0;
    if (s > 60) {
        m = parseInt(s / 60);
        s = parseInt(s % 60);
        if (m > 60) {
            h = parseInt(m / 60);
            m = parseInt(m % 60);
        }
    }
    let result = s + '秒';
    if (m > 0) {
        result = m + '分' + result;
    }
    if (h > 0) {
        result = h + '小时' + result;
    }
    return result;
}

Object.assign(Date.prototype, { format });

export { formatDate, daysBefore, formatSeconds };
