import CDN from '../config/cdn'
import delayLoad from './delayLoad'

const map = {}

function $loader({ name, src }) {
    const resource = map[name]
    if (resource) {
        return Promise.resolve(resource)
    }

    return delayLoad.delayLoadJS(src).then(() => {
        map[name] = src
    })
}

function $loadJWeixin() {
    return $loader({ name: 'jweixin', src: CDN.JWeixin })
}

/**
 * 加密算法依赖
 * @returns {Promise}
 */
function $loadSHA() {
    return $loader({ name: 'sha', src: CDN.SHA })
}

function qrCode() {
    return $loader({ name: 'qrCode', src: CDN.qrCode })
}

function $loadThumbnail() {
    return $loader({ name: 'thumbnail', src: CDN.thumbnail })
}

function $loadCropperJs() {
    return delayLoad.delayLoadCSS(CDN.cropperCss).then(() => {
        return delayLoad.delayLoadJS(CDN.cropperJs)
    })
}

function $loadEchartsChina() {
    return delayLoad.delayLoadCSS(CDN.cropperCss).then(() => {
        return delayLoad.delayLoadJS(CDN.cropperJs)
    })
}

function $loadEcmpCommons() {
    return $loader({ name: 'ecmpCommons', src: CDN.ecmpCommons })
}

export { $loadJWeixin, $loadSHA, qrCode, $loadCropperJs, $loadEchartsChina, $loadThumbnail, $loadEcmpCommons }
