import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import {
    Alert,
    Button,
    Option,
    Select,
    Checkbox,
    Collapse,
    CollapseItem,
    CheckboxGroup,
    Dialog,
    Form,
    FormItem,
    Input,
    Radio,
    RadioGroup,
    Message,
    MessageBox,
    Breadcrumb,
    DatePicker,
    Carousel,
    CarouselItem,
    Image,
    Icon,
    Cascader,
    CascaderPanel,
    Progress,
    Popover,
    Upload,
    Tooltip,
    Pagination,
    Switch,
    Table,
    TableColumn,
    Row,
    Col,
    Loading,
    Divider,
    Drawer,
    Tabs,
    TabPane,
    BreadcrumbItem,
    ButtonGroup,
    RadioButton,
    Tag,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    InfiniteScroll,
    Empty,
    Tree,
    Link,
    InputNumber,
    Autocomplete,
    Slider,
    Badge,
    OptionGroup,
    Card
} from 'element-ui'

export default {
    install(Vue) {
        Vue.prototype.$ELEMENT = { size: 'medium' }

        Vue.use(Alert)
        Vue.use(Button)
        Vue.use(Option)
        Vue.use(Select)
        Vue.use(Checkbox)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(CheckboxGroup)
        Vue.use(Dialog)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(DatePicker)
        Vue.use(Breadcrumb)
        Vue.use(Carousel)
        Vue.use(CarouselItem)
        Vue.use(Image)
        Vue.use(Icon)
        Vue.use(Cascader)
        Vue.use(CascaderPanel)
        Vue.use(Progress)
        Vue.use(Popover)
        Vue.use(Pagination)
        Vue.use(Upload)
        Vue.use(Tooltip)
        Vue.use(Switch)
        Vue.use(Table)
        Vue.use(TableColumn)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Loading)
        Vue.use(Divider)
        Vue.use(Drawer)
        Vue.use(Tabs)
        Vue.use(TabPane)
        Vue.use(BreadcrumbItem)
        Vue.use(ButtonGroup)
        Vue.use(RadioButton)
        Vue.use(Tag)
        Vue.use(Dropdown)
        Vue.use(DropdownItem)
        Vue.use(DropdownMenu)
        Vue.use(InfiniteScroll)
        Vue.use(Empty)
        Vue.use(Tree)
        Vue.use(Link)
        Vue.use(InputNumber)
        Vue.use(Autocomplete)
        Vue.use(Slider)
        Vue.use(Badge)
        Vue.use(OptionGroup)
        Vue.use(Card)
        Vue.component(CollapseTransition.name, CollapseTransition)
        Vue.prototype.$messagebox = MessageBox
        // 引入提交内容框
        Vue.prototype.$prompt = MessageBox.prompt
        // Vue.prototype.$alert = MessageBox.alert
        // Vue.prototype.$confirm = MessageBox.confirm

        Vue.prototype.$message = function (options) {
            if (!options.type) {
                options.type = 'success'
            }
            Object.assign(options, {
                customClass: 'message-toast-box',
                iconClass: getTipType(options)
            })
            Message.info(options)
        }
        Vue.prototype.$message.error = function (msg) {
            if (msg) {
                const options = {
                    message: msg,
                    type: 'error'
                }
                Object.assign(options, {
                    customClass: 'message-toast-box',
                    iconClass: getTipType(options)
                })
                Message.info(options)
            }
        }

        Vue.prototype.$confirm = function (msg, options) {
            Object.assign(options, {
                customClass: options.dangerouslyUseHTMLString ? 'message-box has-sub-cnt' : 'message-box',
                iconClass: getTipType(options),
                cancelButtonClass: 'cancel-btn',
                confirmButtonClass: 'confirm-btn',
                showCancelButton: true,
                showClose: false
            })
            options.message = msg
            return this.$messagebox(options)
        }

        Vue.prototype.$confirmNew = function (msg, options) {
            Object.assign(options, {
                customClass: 'message-confirm',
                iconClass: getTipType(options),
                cancelButtonClass: 'cancel-btn',
                confirmButtonClass: 'confirm-btn',
                showCancelButton: true,
                showClose: false
            })
            options.message = msg
            return this.$messagebox(options)
        }

        Vue.prototype.$msgbox = function (options) {
            const target = {
                customClass: 'message-box',
                iconClass: getTipType(options),
                cancelButtonClass: 'cancel-btn',
                confirmButtonClass: 'confirm-btn',
                showCancelButton: true,
                showClose: false
            }
            Object.assign(target, options)
            return this.$messagebox(target)
        }

        Vue.prototype.$alert = function (options) {
            Object.assign(options, {
                customClass: 'message-box',
                iconClass: getTipType(options),
                confirmButtonClass: 'confirm-btn',
                showCancelButton: false,
                showClose: false
            })
            return this.$messagebox(options)
        }
    }
}

function getTipType(options) {
    let iconClass = 'message-toast-box-' + options.type
    if (options.type === 'success') {
        iconClass += ' eqf-yes-f'
    } else if (options.type === 'warning') {
        iconClass += ' eqf-alert-f'
    } else if (options.type === 'info') {
        iconClass += ' eqf-info-f'
    } else if (options.type === 'error') {
        iconClass += ' eqf-no-f'
    }
    return iconClass
}
