export default {
    echarts: '//asset.eqxiu.cn/libs/echarts/v1/echarts.min.js',
    qrCode: '//asset.eqxiu.cn/libs/lrsjng.jquery-qrcode/v1/jquery-qrcode.min.js',
    zTreeCSS: '//asset.eqxiu.cn/libs/zTree.v3/v1/css/zTreeStyle.min.css',
    zTreeCore: '//asset.eqxiu.cn/libs/zTree.v3/v1/js/jquery.ztree.core.min.js',
    zTreeCheck: '//asset.eqxiu.cn/libs/zTree.v3/v1/js/jquery.ztree.excheck.min.js',
    zTreeEdit: '//asset.eqxiu.cn/libs/zTree.v3/v1/js/jquery.ztree.exedit.min.js',
    cropperJs: '//asset.eqxiu.cn/libs/cropperjs/v1/cropper.min.js',
    cropperCss: '//asset.eqxiu.cn/libs/cropperjs/v1/cropper.min.css',
    echartChina: '//asset.eqxiu.cn/libs/echarts/map/js/china.js',
    thumbnail: '//asset.eqxiu.cn/libs/thumbnail/v1/thumbnail.min.js',
    ecmpCommons: `//asset.eqxiu.cn/libs/@eqxiu/commons/v1/ecmp-commons.js`
}
