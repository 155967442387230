<!--
 * @Description:
 * @Author: wuqian
 * @Date: 2021/12/31 17:42
-->
<template>
  <div class="table-empty" :style="{minHeight: height + 'px'}">
    <SvgIcon class="table-empty__icon" :name="svgName || 'eqx-empty'" :size="svgSize"></SvgIcon>
    <div class="table-empty__text">
      <slot name="text">
        {{text}}
      </slot>
    </div>
  </div>
</template>
<script>
import {ref} from '@vue/composition-api'

export default {
  name: 'TableEmpty',
  props: {
    text: String,
    height: {
      type: Number,
      default: 176
    },
    svgName: String,
    svgSize: {
      type: Object,
      default: () => ({width: 120, height: 100})
    }
  },
  setup() {
  }
}
</script>
<style lang="scss" scoped>
.table-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 40px 0;
  .table-empty__icon {
    margin-bottom: 16px;
    flex-shrink: 0;
  }
  .table-empty__text {
    color: #999999;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
}
</style>

