const host = require('env').host
/**
 * 给定 url, 返回一个 window.URL 实例
 * @param url
 * @returns {*|URL}
 */
function parseUrl(url) {
    if (url.startsWith('//')) {
        url = location.protocol + url
    }
    return new window.URL(url)
}

/**
 * @param pageId
 */
function setUrlHistory(pageId) {
    var url = location.href
    var toPage = getUrlParam('toPage', url)
    if (toPage) {
        if (toPage !== '' + pageId) {
            url = url.replace(toPage, pageId)
        }
    } else {
        url += (/\?/.test(url) ? '&' : '?') + 'toPage=' + pageId
    }
    window.history.replaceState(
        {
            title: $('title').html(),
            url: url
        },
        $('title').html(),
        url
    )
}

/**
 * 获取url中的参数
 * @ref http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 */
function getUrlParam(name, url = window.location.href) {
    return parseUrl(url).searchParams.get(name)
}

/**
 * 删除url中参数
 * @returns {String}
 */
function removeUrlParam(param, url = window.location.href) {
    var obj = parseUrl(url)
    obj.searchParams.delete(param)
    return obj.href
}

/**
 * 删除url中的参数
 * @param params
 * @param url
 * @returns {String}
 */
function removeUrlParams(params = [], url = window.location.href) {
    var obj = parseUrl(url)
    params.forEach(param => {
        obj.searchParams.delete(param)
    })
    return obj.href
}

/**
 * 增加url中的参数
 * @param key
 * @param value
 * @param url
 * @returns {String}
 */
function addUrlParam(key, value, url = window.location.href) {
    var obj = parseUrl(url)
    obj.searchParams.set(key, value)
    return obj.href
}

/**
 * 修改当前url 不刷新界面 通过修改history
 */
function replaceLocationHref(nowUrl) {
    var oldUrl = window.location.href
    history.replaceState({}, oldUrl, nowUrl)
}

/**
 * 获取domain
 * 举例: url = "http://jira.yqxiu.cn/browse/REFACT-17"
 * 返回 "jira.yqxiu.cn"
 */
function getUrlHost(url = window.location.href) {
    return parseUrl(url).host
}

/**
 * 返回url的pathName
 * @param url
 */
function getPathName(url = window.location.href) {
    var obj = parseUrl(url)
    return obj.pathname
}

/**
 * 解决url resolve 问题
 * https://github.com/lydell/resolve-url/blob/master/resolve-url.js
 * @returns {*}
 */
function resolveUrl(...args) {
    var len = args.length

    if (len === 0) {
        throw new Error('resolveUrl requires at least one argument; got none.')
    }

    var base = document.createElement('base')
    base.href = args[0]

    if (len === 1) {
        return base.href
    }

    var head = document.getElementsByTagName('head')[0]
    head.insertBefore(base, head.firstChild)

    var a = document.createElement('a')
    var resolved

    for (var index = 1; index < len; index++) {
        a.href = args[index]
        resolved = a.href
        base.href = resolved
    }

    head.removeChild(base)

    return resolved
}

function addProtocol(url) {
    var protocol = location.protocol

    if (protocol != 'https:') {
        protocol = 'http:'
    }
    if (!/^\w{2,}:\/\//.test(url)) {
        url = '//' + url
    }
    return url.replace(/^\/\//, protocol + '//')
}

function url2http(url) {
    return addProtocol(url).replace(/^https:/, 'http:')
}

function url2https(url) {
    return addProtocol(url).replace(/^http:/, 'https:')
}

function getFullUrl(url) {
    return /^(https?:)?\/\//.test(url) ? url : host.file + url
}

// 获取替换域名后的url地址
function getReplaceUrl(url, host) {
    const urlHost = getUrlHost(url)
    var replaceUrl = url.replace(urlHost, host)
    return replaceUrl
}

module.exports = {
    parseUrl,
    getUrlParam,
    removeUrlParam,
    removeUrlParams,
    addUrlParam,
    replaceLocationHref,
    getUrlHost,
    resolveUrl,
    setUrlHistory,
    getPathName,
    addProtocol,
    url2http,
    url2https,
    getFullUrl,
    getReplaceUrl
}
