var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "icon-svg",
      attrs: {
        "aria-hidden": "true",
        width: _vm.svgSize.width,
        height: _vm.svgSize.height,
      },
      on: { click: _vm.emitClick },
    },
    [_c("use", { attrs: { "xlink:href": _vm.icon } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }