import plTable from 'pl-table'
import 'pl-table/themes/index.css'
import SvgIcon from './SvgIcon.vue'
import TableEmpty from './TableEmpty.vue'
// 全局组件注入
export default {
	install(app) {
		const GlobalComps = [ plTable ]
		GlobalComps.forEach((item) => {
			app.use(item)
		})
		app.component(SvgIcon.name, SvgIcon)
		app.component(TableEmpty.name, TableEmpty)
	}
}
