<template>
  <svg
    class="icon-svg"
    aria-hidden="true"
    :width="svgSize.width"
    :height="svgSize.height"
    @click="emitClick"
  >
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script>
import { computed } from '@vue/composition-api'

const context = require.context('@/assets/svgs', true, /\.(svg)$/)
context.keys().forEach((filename) => {
  require('@/assets/svgs/' + filename.replace('./', ''))
})

export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [Number, Object],
      default: () => ({
        width: 16,
        height: 16
      }),
    },
  },
  emits: ['click'],
  setup(props, ctx) {
    function emitClick(e) {
      ctx.emit('click', e)
    }
    const icon = computed(() => {
      return `#${props.name}`
    })

    const svgSize = computed(() => {
      if (typeof props.size === 'number') {
        return {width: props.size, height: props.size}
      }
      return props.size
    })
    return {
      icon,
      emitClick,
      svgSize
    }
  },
}
</script>
<style lang="scss" scoped>
.icon-svg {
  &:focus {
    outline: none;
  }
}
</style>
